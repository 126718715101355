import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import DashBoardNavbar from "../../generic/dashboard/DashBoardNavbar";
import DashBoardHeader from "../../generic/dashboard/DashBoardHeader";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { getLocation, getUser } from "../../../global/redux/action";
import { useEffect } from "react";
import { config } from "../../../config/config";
import { useNavigate } from "react-router-dom";

const PropertyManagerTabs = (props) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );
  const { locationData, locationError, isLocationFetching } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
    if (locationError) {
      alert(
        "Something went wrong while fetching Location details. Please try again later!"
      );
    }
  }, [userError, locationError]);

  useEffect(() => {
    // Check if the necessary conditions are met before dispatching
    if (
      cookies[config.cookieName]?.loginUserId &&
      !userData &&
      !isFetching &&
      !userError
    ) {
      dispatch(
        getUser({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
    if (!locationData?.countries && !isLocationFetching && !locationError) {
      dispatch(
        getLocation({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [
    cookies,
    userData,
    isFetching,
    userError,
    locationError,
    locationData,
    isLocationFetching,
  ]);
  const { children, tabActive } = props;
  const [value, setValue] = useState(tabActive || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Use the navigate function to navigate to the desired route
    switch (newValue) {
      case 0:
        navigate("/post-property"); // Specify the route you want to navigate to
        break;
      case 1:
        navigate("/my-property-lists");
        break;
      case 2:
        navigate("/grouping-property");
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          position: "fixed",
          width: "100%",
        }}
      >
        <DashBoardNavbar />
        <Box
          component="header"
          sx={{
            flexGrow: 1,
            p: 2,
            backgroundColor: "#F6F8FB",
            overflowX: "auto", // Add horizontal scrolling for small screens
          }}
        >
          <DashBoardHeader />
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ width: "100%", mb: 2 }}
          >
            <Tab label="Add Property" sx={{ backgroundColor: "white" }} />
            <Tab
              label="Active Properties list"
              sx={{ backgroundColor: "white" }}
            />
            {userData?.user.roles.includes("Admin", "Associate") ||
              (userData?.user?.roles.includes("Agent") && (
                <Tab label="Grouping" sx={{ backgroundColor: "white" }} />
              ))}
          </Tabs>
          <TabPanel value={value} index={tabActive}>
            {children}
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
export default PropertyManagerTabs;
