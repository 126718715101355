import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../generic/Header";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import Footer from "../../generic/Footer";
import { apiList, invokeApi } from "../../../apis/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import LeadModal from "../LeadModal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HouseIcon from "@mui/icons-material/House";
import ChairIcon from "@mui/icons-material/Chair";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import BedIcon from "@mui/icons-material/Bed";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Loader from "../../Loader";
import FurnishingStatus from "./components/FurnishingStatus";
import Landmarks from "./components/LandMark";
import {
  PriceFormatter,
  displayMobileNumber,
  formatSegment,
} from "../../../common/common";
import OverViewDetailsLeadForm from "./components/OverViewDetailsLeadForm";
import defaultImage from "./components/img1.png";
import PropertyDetails from "./components/PropertyDetails";
import PricingDetails from "./components/PricingDetails";
import DefineProperty from "./components/DefineProperty";
import AreaDetails from "./components/AreaDetails";
import AdditionalDetails from "./components/AdditionalDetails";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import { Helmet } from "react-helmet";
import ImageViewer from "react-simple-image-viewer";
import "../style.css";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const OverViewDetails = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [propertyData, setpropertyData] = useState([]);
  const [leadModal, setLeadModal] = useState(false);
  const [value, setValue] = useState("overview");
  const [userDetailsModal, setUserDetailModal] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = propertyData.propertyGalleryImages?.map(
    (item) => item.imagePath
  );

  let allFurnishingStatusNull;

  if (!fetching) {
    allFurnishingStatusNull = Object.entries(propertyData?.furnishingStatus)
      ?.filter(
        ([key]) =>
          key !== "id" &&
          key !== "updatedBy" &&
          key !== "updatedDate" &&
          key !== "createdBy" &&
          key !== "createdDate" &&
          key !== "status" &&
          key !== "limit" &&
          key !== "offset" &&
          key !== "officeSpaceType" &&
          key !== "managedType" &&
          key !== "seatType" &&
          key !== "workStationType" &&
          key !== "sofaType"
      ) // Exclude irrelevant properties
      .every(([_, value]) => value === "No" || value === null);
  }
  {
    /* Condition for the not showing the pricing Details if it is empty*/
  }
  const pricingdetailsRenderTab =
    propertyData.pricingDetails?.rent ||
    propertyData.pricingDetails?.maintananceCost ||
    propertyData.pricingDetails?.securityDeposit ||
    propertyData.pricingDetails?.depositAmount ||
    propertyData.pricingDetails?.bookingAmount ||
    propertyData.pricingDetails?.lockInPeriod ||
    propertyData.pricingDetails?.rentIncrement;

  {
    /* Condition for the not showing the property AreaDetails if it is empty*/
  }
  const areaDetailsRenderTab =
    propertyData.propertyAreaDetails?.builtupPlotArea ||
    propertyData.propertyAreaDetails?.carpetArea ||
    propertyData.propertyAreaDetails?.superBuiltupArea ||
    propertyData.propertyAreaDetails?.areaUnit ||
    propertyData.propertyAreaDetails?.privatePoolAvailability ||
    propertyData.propertyAreaDetails?.privateGardenAvailability ||
    propertyData.propertyAreaDetails?.noOfBedrooms ||
    propertyData.propertyAreaDetails?.noOfBathrooms ||
    propertyData.propertyAreaDetails?.noOfBalconies ||
    propertyData.propertyAreaDetails?.additionalRooms ||
    propertyData.propertyAreaDetails?.floorNo ||
    propertyData.propertyAreaDetails?.towerBlockNo ||
    propertyData.propertyAreaDetails?.flatNo ||
    propertyData.propertyAreaDetails?.propertyLevel ||
    propertyData.propertyAreaDetails?.cornerFlat ||
    propertyData.propertyAreaDetails?.terraceArea ||
    propertyData.propertyAreaDetails?.totalFloors ||
    propertyData.propertyAreaDetails?.openSides ||
    propertyData.propertyAreaDetails?.facing ||
    propertyData.propertyAreaDetails?.occupancyType ||
    propertyData.propertyAreaDetails?.privateWashroom ||
    propertyData.propertyAreaDetails?.publicWashroom ||
    propertyData.propertyAreaDetails?.gardenArea ||
    propertyData.propertyAreaDetails?.pantry ||
    propertyData.propertyAreaDetails?.buildingStatus ||
    propertyData.propertyAreaDetails?.spaceType ||
    propertyData.propertyAreaDetails?.breadthFeet ||
    propertyData.propertyAreaDetails?.lengthFeet ||
    propertyData.propertyAreaDetails?.noOfFloorsAllowed ||
    propertyData.propertyAreaDetails?.compoundWallMade ||
    propertyData.propertyAreaDetails?.salableArea ||
    propertyData.propertyAreaDetails?.plotArea ||
    propertyData.propertyAreaDetails?.additionalRoomsList;

  {
    /* Condition for the not showing the Property Details if it is empty*/
  }
  const propertydetailsRenderTab =
    propertyData.propertyStatus?.availableFor ||
    propertyData.propertyStatus?.positionStatus ||
    propertyData.propertyStatus?.availableFrom ||
    propertyData.propertyStatus?.ageOfProperty ||
    propertyData.propertyStatus?.ownershipType ||
    propertyData.propertyStatus?.aboutPropertySuitableFor ||
    propertyData.propertyStatus?.locationHub ||
    propertyData.propertyStatus?.entranceWidth ||
    propertyData.propertyStatus?.heightSealing ||
    propertyData.propertyStatus?.locatedNear ||
    propertyData.propertyStatus?.govtApproved ||
    propertyData.propertyStatus?.tenantPreLeasedUnit;

  {
    /* Condition for the not showing the Define Property if it is empty*/
  }
  const definePropertyRenderTab =
    propertyData.defineProperty?.defineLocation ||
    propertyData.defineProperty?.explainingPrice ||
    propertyData.defineProperty?.explainingProperty ||
    propertyData.defineProperty?.defineSizeAndStructure;

  {
    /* Condition for the not showing the Additional Details if it is empty*/
  }
  const additionalDetailsRenderTab =
    propertyData.additionalDetails?.currentlyLeasedOut ||
    propertyData.additionalDetails?.modifyInterior ||
    propertyData.additionalDetails?.brandNewInterior ||
    propertyData.additionalDetails?.interestedInCoWorking ||
    propertyData.additionalDetails?.buildingGrade ||
    propertyData.additionalDetails?.cafeteria ||
    propertyData.additionalDetails?.taxGovtCharges ||
    propertyData.additionalDetails?.electricityCharges ||
    propertyData.additionalDetails?.powerInKv ||
    propertyData.additionalDetails?.liftAvailable ||
    propertyData.additionalDetails?.flooringType ||
    propertyData.additionalDetails?.flooringLiving ||
    propertyData.additionalDetails?.flooringKitchen ||
    propertyData.additionalDetails?.flooringBedroom ||
    propertyData.additionalDetails?.flooringMasterBedroom ||
    propertyData.additionalDetails?.flooringBalcony ||
    propertyData.additionalDetails?.flooringOther ||
    propertyData.additionalDetails?.parking2Wheeler ||
    propertyData.additionalDetails?.parking4Wheeler ||
    propertyData.additionalDetails?.powerBackup ||
    propertyData.additionalDetails?.waterSource ||
    propertyData.additionalDetails?.overLookingView ||
    propertyData.additionalDetails?.frontRoadWidth ||
    propertyData.additionalDetails?.serviceLiftAvailability ||
    propertyData.additionalDetails?.flooringBathroom;

  {
    /* Condition for the not showing the Tenant Details if it is empty*/
  }
  const tenantdetailsRenderTab =
    propertyData.tenantStatus?.tenantType ||
    propertyData.tenantStatus?.religiousType ||
    propertyData.tenantStatus?.workPreference ||
    propertyData.tenantStatus?.petsAllowed ||
    propertyData.tenantStatus?.foodPreference ||
    propertyData.tenantStatus?.bachelorsAllowed ||
    propertyData.tenantStatus?.sprinstersAllowed;

  const featuredImagePath = propertyData.propertyGalleryImages?.find(
    (item) => item.imageType === "featured"
  )?.imagePath;

  // Function to handle image load error
  const handleImageError = (event) => {
    event.target.src = defaultImage; // Set to default image path
  };

  const paperRefs = {
    overview: useRef(null),
    amenities: useRef(null),
    furnishing: useRef(null),
    location: useRef(null),
    gallery: useRef(null),
    propertydetails: useRef(null),
    pricingdetails: useRef(null),
    defineproperty: useRef(null),
    areadetails: useRef(null),
    tenantdetails: useRef(null),
    additionaldetails: useRef(null),
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Scroll to the corresponding Paper component
    if (paperRefs[newValue].current) {
      paperRefs[newValue].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handeChangeModal = () => setLeadModal((prevState) => !prevState);

  useEffect(() => {
    if (!propertyId) {
      navigate(
        "/property/" +
          (cookies[config.preferencesCookie]?.listingType?.toLowerCase() ||
            null)
      );
    }
  }, [cookies, propertyId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function leadProvided(propertyId) {
    // Retrieve the current array from localStorage
    let existingLeads = localStorage.getItem(config.leadProvided);

    // Parse the retrieved value to get the array, if it exists, otherwise initialize an empty array
    existingLeads = existingLeads ? JSON.parse(existingLeads) : [];

    // Add the new element to the array
    existingLeads.push(propertyId);

    // Save the updated array back to localStorage
    localStorage.setItem(config.leadProvided, JSON.stringify(existingLeads));
  }

  useEffect(() => {
    const getPropertyDetailsById = async () => {
      let params = { id: propertyId };
      const response = await invokeApi(
        config.apiDomains + apiList.getPropertyDetailsById,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setpropertyData(response.data.Properties);
          setFetching(false);
        } else {
          alert("Error Occurred while fetching property overview data!");
        }
      } else {
        alert("Error Occurred while fetching property overview data!");
      }
    };
    if (fetching) {
      getPropertyDetailsById();
    }
  }, [cookies, fetching]);

  const googleMapsUrl = `https://maps.google.com/maps?q=${propertyData?.latitude},${propertyData?.longitude}&hl=es&z=14&amp;output=embed`;

  const userWhatsappNumber = propertyData.userWhatsappNumber || "9686522212";

  const checkLeadProvided = localStorage
    .getItem(config.leadProvided)
    ?.includes(propertyData.id);

  const displayBedrooms = (property) => {
    const { numOfBedrooms } = property || {};

    if (!numOfBedrooms) return "";

    if (numOfBedrooms === "Studio" || numOfBedrooms === "1 RK") {
      return numOfBedrooms;
    }

    return `${numOfBedrooms} BHK`;
  };

  // const propertyTitle = `
  //   ${propertyData.furnishingStatus?.furnishingType || ""}
  //   ${displayBedrooms(propertyData.propertyAreaDetails?.noOfBedrooms)}
  //   ${propertyData?.propertyType || ""} for ${propertyData?.listingType || ""}
  //   in ${propertyData?.locality ? propertyData.locality + "," : ""} ${
  //   propertyData?.city || ""
  // }
  // `.trim();

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Get My Housing - {propertyData?.title || "Loading..."}</title>
        <meta
          property="og:title"
          content={`Get My Housing - ${propertyData?.title || ""}`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={featuredImagePath || defaultImage} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      {fetching ? (
        <Box sx={{ height: "100svh", display: "flex", alignItems: "center" }}>
          <Loader />
        </Box>
      ) : (
        <>
          {/* breadcrumbs */}
          <Box
            role="presentation"
            sx={{
              px: 2,
              width: "100%",
              overflow: "hidden",
              marginTop: { md: "101px", xs: "72px" },
              py: 2,
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <StyledBreadcrumb
                component="a"
                href="/"
                label="Home"
                icon={<HomeIcon fontSize="small" />}
              />
              <StyledBreadcrumb
                component="a"
                href={`/property/${
                  cookies[
                    config.preferencesCookie
                  ]?.listingType?.toLowerCase() || null
                }`}
                label="Property List"
              />
              <StyledBreadcrumb label={propertyData?.title} />
            </Breadcrumbs>
          </Box>

          <Box
            sx={{
              position: "relative",
              padding: "10px",
              // maxHeight: "100svh",
            }}
          >
            <Grid container sx={{ zIndex: "30", gap: "30px" }}>
              {/* below the grid have the property image  */}
              <Grid
                item
                md={6}
                xs={12}
                sx={{
                  display: "grid",
                  placeItems: "center",
                  position: "relative",
                }}
              >
                <img
                  src={featuredImagePath || defaultImage}
                  alt="img"
                  height="400px"
                  width="100%"
                  style={{ borderRadius: "18px", objectFit: "contain" }}
                  loading="lazy" // Lazy loading
                  onError={handleImageError} // Error handling
                />

                <Box
                  sx={{
                    position: "absolute",
                    right: "30px",
                    top: "30px",
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                    padding: "7px 15px",
                    borderRadius: "25px",
                  }}
                >
                  {propertyData?.listingType}
                </Box>
                {propertyData.username && propertyData.userRole ? (
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "50px",
                      position: "absolute",
                      bottom: "20px",
                      left: "20px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      px: 1,
                      gap: "15px",
                      paddingRight: 2,
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        textTransform: "uppercase",
                      }}
                    >
                      {propertyData?.username[0]}
                    </Avatar>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{propertyData?.username}</strong>
                      <Typography variant="caption">
                        {propertyData?.userRole}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Grid>
              {/* below the grid have basic and import details of the proeprty  */}
              <Grid item md={5} xs={12}>
                <Typography
                  sx={{
                    fontSize: { md: "18px", xs: "13px" },
                    fontWeight: "400",
                    color: "#95a5a6",
                  }}
                >
                  {propertyData?.propertyName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: "20px", xs: "17px" },
                    marginTop: "5px",
                    lineHeight: "30px",
                    fontWeight: 700,
                  }}
                >
                  {propertyData?.title}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { md: "28px", xs: "22px" },
                    marginTop: "15px",
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  {PriceFormatter(propertyData.pricingDetails?.rent)}
                </Typography>
                {/* below the grid container contain the list of two column highlight points  */}
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={6}>
                    <List>
                      {/* below the listitem have the bedroom or bathroom point showing  */}
                      <ListItem disablePadding>
                        {propertyData.propertyAreaDetails?.noOfBedrooms && (
                          <>
                            <ListItemIcon>
                              <BedIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                propertyData.propertyAreaDetails?.noOfBedrooms +
                                " Bedrooms"
                              }
                              primaryTypographyProps={{
                                color: "#333",
                                fontWeight: "medium",
                                variant: "body2",
                              }}
                            />
                          </>
                        )}
                      </ListItem>
                      {/* below the list item have the furnishing status like unfurnish, semi furnish and fully furnished  */}
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <ChairIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            propertyData?.furnishingStatus?.furnishingType
                          }
                          primaryTypographyProps={{
                            color: "#333",
                            fontWeight: "medium",
                            variant: "body2",
                          }}
                        />
                      </ListItem>
                      {/* below the list item have the area size and unit  */}
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <HouseIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            (propertyData.propertyAreaDetails
                              ?.builtupPlotArea && (
                              <>
                                {
                                  propertyData.propertyAreaDetails
                                    .builtupPlotArea
                                }{" "}
                                {propertyData.propertyAreaDetails.areaUnit}{" "}
                                (Built-up Area)
                              </>
                            )) ||
                            (propertyData.propertyAreaDetails?.carpetArea && (
                              <>
                                {propertyData.propertyAreaDetails.carpetArea}{" "}
                                {propertyData.propertyAreaDetails.areaUnit}{" "}
                                (Carpet Area)
                              </>
                            )) ||
                            (propertyData.propertyAreaDetails?.salebleArea && (
                              <>
                                {propertyData.propertyAreaDetails.salebleArea}{" "}
                                {propertyData.propertyAreaDetails.areaUnit}{" "}
                                (Saleable Area)
                              </>
                            )) ||
                            (propertyData.propertyAreaDetails
                              ?.superBuiltupArea && (
                              <>
                                {
                                  propertyData.propertyAreaDetails
                                    .superBuiltupArea
                                }{" "}
                                {propertyData.propertyAreaDetails.areaUnit}{" "}
                                (Super Built-up Area)
                              </>
                            )) ||
                            (propertyData.propertyAreaDetails?.plotArea && (
                              <>
                                {propertyData.propertyAreaDetails.plotArea}{" "}
                                {propertyData.propertyAreaDetails.areaUnit}{" "}
                                (Plot Area)
                              </>
                            ))
                          }
                          primaryTypographyProps={{
                            color: "#333",
                            fontWeight: "medium",
                            variant: "body2",
                          }}
                        />
                      </ListItem>
                      {/* below the list item have the facing of the proeprty  */}
                      {propertyData.propertyAreaDetails?.facing && (
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <CompareArrowsIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={propertyData.propertyAreaDetails?.facing}
                            primaryTypographyProps={{
                              color: "#333",
                              fontWeight: "medium",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <AutorenewIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={propertyData.propertyStatus?.positionStatus}
                          primaryTypographyProps={{
                            color: "#333",
                            fontWeight: "medium",
                            variant: "body2",
                          }}
                        />
                      </ListItem>
                      {propertyData.additionalDetails?.overLookingView && (
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <PreviewIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              propertyData.additionalDetails?.overLookingView
                            }
                            primaryTypographyProps={{
                              color: "#333",
                              fontWeight: "medium",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                      )}
                      {propertyData.propertyAreaDetails?.floorNo && (
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LocalConvenienceStoreIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              propertyData.propertyAreaDetails?.floorNo +
                              " Floor"
                            }
                            primaryTypographyProps={{
                              color: "#333",
                              fontWeight: "medium",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  {propertyData.includedGroupProperty?.length > 0 && (
                    <>
                      <TableContainer
                        component={Paper}
                        sx={{
                          maxWidth: "100%",
                          overflowX: "auto",
                          "&::-webkit-scrollbar": {
                            height: "2px",
                          },
                          "&::-webkit-scrollbar-track": {
                            background: "red",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "red",
                            borderRadius: "25px",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            background: "#0d47a1",
                          },
                          // scrollbarWidth: "thin",
                          scrollbarColor: "#1976d2 #f1f1f1",
                          mt: 1,
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow sx={{ height: 40 }}>
                              <TableCell
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                  background: "#dfe6e9",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                BHK
                              </TableCell>
                              {propertyData.includedGroupProperty?.map(
                                (item, index) => (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 500,
                                      padding: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {displayBedrooms(
                                      item?.displayBedrooms(item?.numOfBedrooms)
                                    )}
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                            <TableRow sx={{ height: 40 }}>
                              <TableCell
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                  background: "#dfe6e9",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Area
                              </TableCell>
                              {propertyData.includedGroupProperty?.map(
                                (item, index) => (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 500,
                                      padding: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.builtupPlotArea ||
                                      item.carpetArea ||
                                      item.salebleArea ||
                                      item.superBuiltupArea ||
                                      item.plotArea}{" "}
                                    {item.areaUnit}
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                            <TableRow sx={{ height: 40 }}>
                              <TableCell
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                  background: "#dfe6e9",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Price
                              </TableCell>
                              {propertyData.includedGroupProperty?.map(
                                (item, index) => (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 500,
                                      padding: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {PriceFormatter(item.rent)}
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </Grid>
                {propertyData.approvalStatus === "Completed" && (
                  <div className="watermar-detail-page">Not Available</div>
                )}
                {/* below the grid container contain the buttons like whatsapp and contact us  */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      startIcon={<WhatsAppIcon />}
                      sx={{
                        backgroundColor: "#25d366",
                        border: "1px solid #25d366",
                        color: "#fff",
                        borderRadius: "35px",
                        padding: "10px", // Adjust padding for better visualization
                        transition: "transform 0.3s", // Add transition for smooth effect
                        "&:hover": {
                          border: "1px solid #25d366",
                          backgroundColor: "#25d366",
                          transform: "scale(1.05)", // Zoom in on hover
                        },
                      }}
                      onClick={() =>
                        window.open(
                          `https://api.whatsapp.com/send?phone=91${userWhatsappNumber}&text=${
                            window.location.origin +
                            "/property/" +
                            formatSegment(propertyData.listingType) +
                            "/" +
                            formatSegment(propertyData.propertyName) +
                            "/" +
                            formatSegment(
                              propertyData.buildingType +
                                "-" +
                                propertyData.propertyType +
                                "-in-" +
                                propertyData.locality +
                                "-" +
                                propertyData.city
                            ) +
                            "/" +
                            propertyData.id
                          }%0AHi! I'm intrested in ${
                            propertyData.propertyName
                          }.Can i have some more info please.`,
                          "_blank"
                        )
                      }
                    >
                      Whatsapp
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      startIcon={<CallIcon />}
                      sx={{
                        backgroundColor: "white",
                        border: "1px solid red",
                        color: "red",
                        borderRadius: "35px",
                        padding: "10px",
                        transition: "background-color 0.3s, transform 0.3s", // Added transition
                        "&:hover": {
                          backgroundColor: "red",
                          color: "#fff",
                          transform: "scale(1.05)", // Zoom effect
                        },
                      }}
                      onClick={() => {
                        if (checkLeadProvided) {
                          setUserDetailModal(true);
                        } else {
                          handeChangeModal();
                        }
                      }}
                    >
                      Contact Us
                    </Button>
                  </Grid>
                  {propertyData.propertyBrochure && (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        class="button"
                        onClick={() =>
                          window.open(propertyData.propertyBrochure, "_blank")
                        }
                      >
                        <svg
                          stroke-linejoin="round"
                          stroke-linecap="round"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          viewBox="0 0 24 24"
                          height="40"
                          width="40"
                          class="button__icon"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="none"
                            d="M0 0h24v24H0z"
                            stroke="none"
                          ></path>
                          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                          <path d="M7 11l5 5l5 -5"></path>
                          <path d="M12 4l0 12"></path>
                        </svg>
                        <span class="button__text">Download</span>
                      </button>
                      {/* <Link
                        to={propertyData.propertyBrochure}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "white",
                          backgroundColor: "#000",
                          padding: "10px 25px",
                          borderRadius: "25px",
                        }}
                      >
                        View Brochure
                      </Link> */}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* below the body of overview  */}
          <Box
            sx={{
              position: "sticky",
              top: { md: "103px", xs: "74px" },
              zIndex: 1100,
              bgcolor: "background.paper",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              maxWidth: "100%",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile={false}
              aria-label="scrollable force tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab value="overview" label="Overview" />
              {propertyData.amenitiesList?.length >= 1 && (
                <Tab value="amenities" label="Amenities" />
              )}
              {!allFurnishingStatusNull || (
                <Tab value="furnishing" label="Furnishing Details" />
              )}
              <Tab value="location" label="Location" />
              {(!propertyData?.propertyGalleryImages.filter(
                (item) => item.imageType === "gallery"
              ).length <= 0 ||
                propertyData.youtubeLink) && (
                <Tab value="gallery" label="Gallery" />
              )}

              {propertydetailsRenderTab && (
                <Tab value="propertydetails" label="Property Details" />
              )}

              {pricingdetailsRenderTab && (
                <Tab value="pricingdetails" label="Pricing Details" />
              )}

              {definePropertyRenderTab && (
                <Tab value="defineproperty" label="Define Property" />
              )}

              {areaDetailsRenderTab && (
                <Tab value="areadetails" label="Area Details" />
              )}

              {tenantdetailsRenderTab && (
                <Tab value="tenantdetails" label="Tenant Details" />
              )}

              {additionalDetailsRenderTab && (
                <Tab value="additionaldetails" label="Additional Details" />
              )}
            </Tabs>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={8} xs={12} sx={{ mt: { md: "15px" } }}>
              {propertyData.defineProperty?.description !== "" && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, width: "100%" }}
                  ref={paperRefs.overview}
                  id="overview"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Description
                  </Typography>
                  <Box
                    sx={{ pt: 1 }}
                    dangerouslySetInnerHTML={{
                      __html: propertyData.defineProperty?.description,
                    }}
                  />
                </Paper>
              )}
              {!allFurnishingStatusNull || (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.furnishing}
                  id="furnishing"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Furnishing Details
                  </Typography>
                  <FurnishingStatus propertyData={propertyData} />
                </Paper>
              )}
              {propertydetailsRenderTab && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.propertydetails}
                  id="propertydetails"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Property Details
                  </Typography>
                  <PropertyDetails propertyData={propertyData} />
                </Paper>
              )}

              {propertyData.amenitiesList?.length >= 1 && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.amenities}
                  id="amenities"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Amenities
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gridColumnGap: "10px", // Column gap of 10 pixels
                      gridRowGap: "10px",
                      pt: 2,
                    }}
                  >
                    {propertyData?.amenitiesList?.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
                          textAlign: "center",
                          p: 1,
                          borderRadius: "10px",
                        }}
                      >
                        {item.amenities}
                      </Box>
                    ))}
                  </Box>
                </Paper>
              )}
              <Paper
                elevation={3}
                sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                ref={paperRefs.location}
                id="location"
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#333",
                    backgroundColor: "#dfe6e9",
                    py: 1,
                    px: 2,
                  }}
                  component="h2"
                >
                  Location And LandMarks
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <iframe
                    width="100%"
                    style={{ pt: 1 }}
                    title="Sub-locality"
                    height="250"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src={googleMapsUrl}
                  ></iframe>
                </Box>
                <Landmarks propertyData={propertyData} />
              </Paper>
              {definePropertyRenderTab && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.defineproperty}
                  id="defineproperty"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Define Property
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <DefineProperty propertyData={propertyData} />
                </Paper>
              )}
              {areaDetailsRenderTab && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.areadetails}
                  id="areadetails"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Area Details
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <AreaDetails propertyData={propertyData} />
                </Paper>
              )}

              {tenantdetailsRenderTab && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.tenantdetails}
                  id="tenantdetails"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Tenant Details
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <AreaDetails propertyData={propertyData} />
                </Paper>
              )}

              {additionalDetailsRenderTab && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.additionaldetails}
                  id="additionaldetails"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Additional Details
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <AdditionalDetails propertyData={propertyData} />
                </Paper>
              )}

              {pricingdetailsRenderTab && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.pricingdetails}
                  id="pricingdetails"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Pricing Details
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <PricingDetails propertyData={propertyData} />
                </Paper>
              )}
              {(!propertyData?.propertyGalleryImages.filter(
                (item) => item.imageType === "gallery"
              ).length <= 0 ||
                propertyData.youtubeLink) && (
                <Paper
                  elevation={3}
                  sx={{ p: { md: 4, xs: 1 }, mt: 2 }}
                  ref={paperRefs.gallery}
                  id="gallery"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333",
                      backgroundColor: "#dfe6e9",
                      py: 1,
                      px: 2,
                    }}
                    component="h2"
                  >
                    Gallery
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  {propertyData.propertyGalleryImages.length > 0 && (
                    <ImageList
                      sx={{ width: "100%", height: "100%", pt: 1 }}
                      cols={3}
                      rowHeight={164}
                    >
                      {propertyData.propertyGalleryImages?.map(
                        (item, index) => (
                          <ImageListItem
                            key={index}
                            onClick={() => openImageViewer(index)}
                          >
                            <img
                              // srcSet={`${item.imagePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                              src={`${item.imagePath}?w=164&h=164&fit=crop&auto=format`}
                              alt={item.imageType}
                              loading="lazy"
                            />
                          </ImageListItem>
                        )
                      )}
                    </ImageList>
                  )}
                  {propertyData.youtubeLink && (
                    <div style={{ maxWidth: "800px", margin: "20px 0" }}>
                      <iframe
                        width="100%"
                        height="400"
                        src={propertyData.youtubeLink}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  )}
                </Paper>
              )}
            </Grid>
            {/* below the grid have the lead form  */}
            <Grid
              item
              md={4}
              sx={{
                position: "sticky",
                top: 130,
                zIndex: 900,
                height: "70vh",
                display: "flex",
                alignItems: "center",
              }}
            >
              <OverViewDetailsLeadForm
                propertyData={propertyData}
                postDetails={true}
                leadProvided={leadProvided}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Footer />
      <LeadModal
        initialValue={leadModal}
        handleModal={handeChangeModal}
        leadSource={propertyData.propertyType}
        propertyId={propertyId}
        productUrl={window.location.href}
        leadProvided={leadProvided}
      />
      <Dialog
        open={userDetailsModal}
        onClose={() => setUserDetailModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          User Details
          <IconButton
            style={{ marginLeft: "250px" }}
            edge="end"
            color="inherit"
            onClick={() => setUserDetailModal(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <Box sx={{ width: { md: "30%", xs: "80%" } }}> */}
          Name: {propertyData?.username}
          <br />
          Role: {propertyData?.userRole}
          <br />
          whatsappNumber:{" "}
          {checkLeadProvided
            ? propertyData?.userWhatsappNumber
            : displayMobileNumber(propertyData?.userWhatsappNumber)}
          {/* </Box> */}
        </DialogContent>
      </Dialog>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 9999,
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  );
};

export default OverViewDetails;
