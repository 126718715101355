import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { config } from "../../config/config";
import { apiList, invokeApi } from "../../apis/apiServices";
import Header from "../generic/Header";
import Footer from "../generic/Footer";

const ViewBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [isBlogDataFetching, setIsBlogDataFetching] = useState(true);
  const [blogData, setBlogData] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [activeHeading, setActiveHeading] = useState("");
  const [scrollPercentage, setScrollPercentage] = useState(0);
  console.log(scrollPercentage);

  useEffect(() => {
    const getBlog = async () => {
      let params = { id: id };
      let response = await invokeApi(
        config.apiDomains + apiList.getBlog,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          const { headings, modifiedContent } = preprocessBlogContent(
            response.data.blog.blogContent
          );

          setIsBlogDataFetching(false);
          setBlogData({ ...response.data.blog, blogContent: modifiedContent });
          setHeadings(headings);
        } else {
          alert(
            "Something went wrong while getting the blog. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the blog. Please try again later!!"
        );
      }
    };

    if (isBlogDataFetching) {
      getBlog();
    }
  }, [id, cookies, isBlogDataFetching]);

  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  // Function to extract headings from HTML content
  function preprocessBlogContent(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const headings = Array.from(doc.querySelectorAll("h2, h3, h4")).map(
      (elem) => {
        const slug = slugify(elem.innerText);
        elem.id = slug; // Inject the id directly into the element
        return {
          id: slug,
          text: elem.innerText,
          level: Number(elem.nodeName.charAt(1)),
        };
      }
    );

    // Serialize the modified HTML back to a string
    const serializedContent = new XMLSerializer().serializeToString(doc.body);
    return { headings, modifiedContent: serializedContent };
  }

  // Inside your ViewBlog component

  const handleToCClick = (slug, event) => {
    event.preventDefault(); // Prevent default anchor link behavior
    const targetElement = document.getElementById(slug);
    if (targetElement) {
      window.history.pushState({}, "", `#${slug}`); // This updates the URL hash
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const tocItemStyle = (level) => ({
    marginLeft: `${level - 2}em`, // Adjust the indentation based on the heading level
    fontWeight: level === 2 ? "bold" : "normal", // Bold for h2, normal for h3 and h4
    listStyleType: "none", // Removes the default list bullet
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  });

  useEffect(() => {
    const handleScroll = () => {
      let nearestHeading = "";
      let nearestHeadingDistance = Number.POSITIVE_INFINITY;

      headings.forEach((heading) => {
        const element = document.getElementById(heading.id);
        // Check if the element exists before proceeding
        if (element) {
          const distance = Math.abs(element.getBoundingClientRect().top);

          if (distance < nearestHeadingDistance) {
            nearestHeadingDistance = distance;
            nearestHeading = heading.id;
          }
        }
      });

      setActiveHeading(nearestHeading);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headings]); // Make sure to update if headings change

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      const scrollPercentage = (windowBottom / documentHeight) * 100;
      setScrollPercentage(scrollPercentage);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial calculation
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <title>{blogData?.metaTitle}</title>
        <meta name="description" content={blogData?.metaDescription} />
        <meta name="keywords" content={blogData?.metaKeywords} />
        <meta property="og:title" content={blogData?.metaTitle} />
        <meta property="og:image" content={blogData?.bannerImageUrl} />
        <meta property="og:description" content={blogData?.metaDescription} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <Grid container sx={{ marginTop: { md: "101px", xs: "72px" } }}>
        <Grid item md={9} sm={12}>
          <Box
            sx={{
              height: "90%",
              margin: "10px",
              position: "relative",
            }}
          >
            <Box sx={{ padding: "30px" }}>
              <Breadcrumbs separator="›">
                <RouterLink
                  underline="hover"
                  component={RouterLink}
                  to="/blogs"
                >
                  Blogs
                </RouterLink>
                <RouterLink underline="hover">
                  <Typography
                    onClick={() => {
                      navigate(
                        `/blogs/${blogData?.categoryName.replace(/\s+/g, "")}/${
                          blogData?.categoryId
                        }`
                      );
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    {blogData?.categoryName}
                  </Typography>
                </RouterLink>

                {blogData?.subCategoryId && (
                  <RouterLink underline="hover">
                    <Typography
                      onClick={() => {
                        navigate(
                          `/blogs/${blogData?.categoryName.replace(
                            /\s+/g,
                            ""
                          )}/${blogData?.subCategoryName.replace(/\s+/g, "")}/${
                            blogData?.subCategoryId
                          }`
                        );
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {blogData?.subCategoryName}
                    </Typography>
                  </RouterLink>
                )}
              </Breadcrumbs>
            </Box>
            {!isBlogDataFetching ? (
              <>
                <Container>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h3" component={"h1"} sx={{ py: 2 }}>
                      {blogData.blogTitle}
                    </Typography>
                    <Box
                      component="img"
                      src={blogData?.bannerImageUrl}
                      alt="Banner"
                      sx={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        backgroundColor: "#eee",
                        ml: 2,
                        maxHeight: "300px", // Set maximum height
                      }}
                    />
                  </Box>
                  <Box
                    className="blog-content"
                    dangerouslySetInnerHTML={{
                      __html: blogData?.blogContent,
                    }}
                    sx={{ my: 2 }}
                  />
                </Container>
              </>
            ) : (
              <>
                <CircularProgress
                  sx={{
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
        {/* // Adjust the extractHeadings function if necessary to ensure it works with your HTML structure.
        // Assuming this part works correctly as you described.

        // In your component's return statement, adjust how you render the ToC. 
        // Replace RouterLink with a regular anchor (<a>) tag for in-page links. */}
        {/* // When rendering your ToC */}
        {headings.length > 0 && (
          <Grid
            item
            md={3}
            sm={12}
            className="table-content"
            style={{
              padding: "20px",
              background: "#f4f4f4",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Table of Contents
            </Typography>
            <ul style={{ listStyle: "none", paddingLeft: "0" }}>
              {headings.map((heading) => (
                <li
                  key={heading.id}
                  className={`head${heading.level}`}
                  style={{
                    marginLeft: `${heading.level * 20}px`,
                    fontWeight:
                      activeHeading === heading.id ? "bold" : "normal",
                  }}
                >
                  <a
                    href={`#${heading.id}`}
                    onClick={(e) => handleToCClick(heading.id, e)}
                    style={{
                      textDecoration: "none",
                      color:
                        activeHeading === heading.id ? "#007bff" : "inherit",
                    }}
                  >
                    {heading.text}
                  </a>
                </li>
              ))}
            </ul>
          </Grid>
        )}
      </Grid>
      <Footer />
      {scrollPercentage > 5 ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "5px",
            backgroundColor: "lightgray",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: `${scrollPercentage}%`,
              backgroundColor: "blue",
              transition: "width 0.2s ease-in-out",
            }}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ViewBlog;
