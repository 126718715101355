import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Card,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import RoofingOutlinedIcon from "@mui/icons-material/RoofingOutlined";
import VillaIcon from "@mui/icons-material/Villa";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useCookies } from "react-cookie";
import { config } from "../../config/config";
import { formatSegment } from "../../common/common";

const Footer = ({ handleChangeFilter }) => {
  const theme = useTheme();
  const location = useLocation();
  const [cookies] = useCookies();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openontactDrawer, setOpenContactDrawer] = useState(false);
  const [openontactDrawer1, setOpenContactDrawer1] = useState(false);
  const [value, setValue] = useState(0);

  const isExactPropertyRoute = matchPath(
    "/property/:listingType?",
    location.pathname
  );

  const handleWhatsappClick = () => {
    // Create the WhatsApp API link
    const whatsappLink = `https://wa.me/${"9686522212"}?text=${encodeURIComponent(
      "Hi"
    )}`;

    // Open WhatsApp in a new tab
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Box component="footer" sx={{ backgroundColor: "#333" }}>
        <Grid container sx={{ marginBottom: "10px" }}>
          <Grid item md={9}>
            <Typography
              sx={{
                fontFamily: "Mulish, sans-serif",

                fontSize: "20px",

                Weight: "400",

                marginTop: { md: "20px" },

                marginLeft: { md: "50px", sm: "30px", xs: "10px" },

                color: "#fff",
              }}
            >
              Other Links
            </Typography>
            <br />
            <Grid container sx={{ gap: { md: 0, xs: 1 } }}>
              <Grid item md={6} xs={12} className="footer-quick-links">
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    justifyContent: "space-around",
                  }}
                >
                  <li onClick={() => navigate("/about-us")}>About Us</li>
                  <li onClick={() => navigate("/terms-conditions")}>
                    Terms & Conditions
                  </li>
                  <li onClick={() => navigate("/contact-us")}>Contact Us</li>
                </ul>
              </Grid>
              <Grid item md={6} xs={12} className="footer-quick-links">
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    justifyContent: "space-around",
                  }}
                >
                  <li>Services</li>
                  <li onClick={() => navigate("/privacy-policy")}>
                    Privacy Policy
                  </li>
                  <li onClick={() => navigate("/blogs")}>Blog</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-end",
              mt: { xs: 2, md: 0 },
            }}
          >
            <Link onClick={handleWhatsappClick}>
              <div className="youtube-icon-container">
                <WhatsAppIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#25d366",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link
              to="https://www.linkedin.com/company/get-my-housing/"
              target="_blank"
            >
              <div className="youtube-icon-container">
                <LinkedInIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#0a66c2",

                    borderRadius: "15px",
                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link to="https://www.instagram.com/getmyhousing/" target="_blank">
              <div className="youtube-icon-container">
                <InstagramIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    background:
                      "linear-gradient(to right, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80)",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61562252853791"
              target="_blank"
            >
              <div className="youtube-icon-container">
                <FacebookIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#1877f2",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link to="https://x.com/Getmyhousing" target="_blank">
              <div className="youtube-icon-container">
                <XIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#000",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link
              to="https://youtube.com/@getmyhousing?si=SGkGYsY3qS_2kTMv"
              target="_blank"
            >
              <div className="youtube-icon-container">
                <YouTubeIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#dd2c00",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
          </Grid>
        </Grid>

        <Divider />
        <Typography
          sx={{
            fontFamily: "Mulish, sans-serif",
            fontSize: "12px",
            color: "#fff",
            textAlign: "center",
            p: 1,
            py: 2,
          }}
        >
          &#169; 2019 Owned By{" "}
          <Link
            to={"https://cgssoftwaresolutions.com/"}
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#fff",
              fontWeight: "700",
            }}
          >
            {" "}
            ValueAid Infrastructure Pvt Ltd
          </Link>
        </Typography>
      </Box>
      {/* Bottom Navigation Bar for Mobile */}
      {isMobile && (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          sx={{ width: "100%", position: "fixed", bottom: 0, zIndex: "999" }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<HomeIcon />}
            onClick={() => navigate("/")}
          />
          {!isExactPropertyRoute && (
            <BottomNavigationAction
              label="Search"
              icon={<SearchIcon />}
              onClick={() =>
                navigate(
                  `/property/${formatSegment(
                    cookies[config.preferencesCookie]?.listingType
                  )}`
                )
              }
            />
          )}
          {isExactPropertyRoute && (
            <BottomNavigationAction
              label="Filter"
              icon={<FilterAltIcon />}
              onClick={() => handleChangeFilter()}
            />
          )}
          <BottomNavigationAction
            label="Profile"
            icon={<PersonOutlineIcon />}
            onClick={() => navigate(`/login`)}
          />
        </BottomNavigation>
      )}
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="bottom"
        PaperProps={{
          sx: { width: "100%", height: "80%" },
        }}
        sx={{ zIndex: 949 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold", fontSize: "17px" }}>
            Contact our Real Estate Expert
          </DialogTitle>
          <IconButton
            onClick={() => {
              setOpenDrawer(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ display: "flex", gap: "2rem", p: 1 }}
          onClick={() => {
            setOpenContactDrawer(true);
          }}
        >
          <Grid item>
            <IconButton>
              <VillaIcon fontSize="large" sx={{ color: "#346c4e" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              Buy a new home
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>Property options</Typography>
          </Grid>
        </Box>
        <Divider />
        <Box
          sx={{ display: "flex", gap: "2rem", p: 1 }}
          onClick={() => {
            setOpenContactDrawer(true);
          }}
        >
          <Grid item>
            <IconButton>
              <DescriptionOutlinedIcon
                fontSize="large"
                sx={{ color: "#9fc5fd" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              Take a Property on Rent
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              Matching your lifestyle
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: "2rem", p: 1 }}>
          <Grid item>
            <IconButton>
              <PersonOutlinedIcon fontSize="large" sx={{ color: "#d96e87" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              Sell or Rent property
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              No Success, No Fee
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Box
          sx={{ display: "flex", gap: "2rem", p: 1 }}
          onClick={() => {
            setOpenContactDrawer1(true);
          }}
        >
          <Grid item>
            <IconButton>
              <AttachMoneyRoundedIcon
                fontSize="large"
                sx={{ color: "#50586d" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              Get Home Loan Offers
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              Paperless approval within minutes
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: "2rem", p: 1 }}>
          <Grid item>
            <IconButton>
              <ApartmentRoundedIcon
                fontSize="large"
                sx={{ color: "#401641" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              Home Interior & Construction
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              From Concept to Completion
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: "2rem", p: 1 }}>
          <Grid item>
            <IconButton>
              <RoofingOutlinedIcon fontSize="large" sx={{ color: "#ee875a" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              Legal or Technical Advice
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              Everything from Disputes to ownership
            </Typography>
          </Grid>
        </Box>
        <Divider />
      </Drawer>
      {/* contact Real Estate Drawer */}
      <Drawer
        open={openontactDrawer}
        onClose={() => setOpenContactDrawer(false)}
        anchor="bottom"
        PaperProps={{
          sx: { width: "100%", height: "50%" },
        }}
        // sx={{zIndex: 949}}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton>
            <PhoneOutlinedIcon fontSize="large" sx={{ color: "#76af70" }} />
          </IconButton>
          <DialogTitle sx={{ fontWeight: "bold", fontSize: "15px" }}>
            Contact our Real Estate Expert
          </DialogTitle>
          <IconButton
            onClick={() => {
              setOpenContactDrawer(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Card
          sx={{
            maxWidth: { xs: "auto" },
            height: "261px",
            // borderRadius: "40px",
            border: "1px solid #7C7E80",
            boxShadow: "0px 0px 4px rgba(124, 126, 128, 1)",
            justifyContent: "center",
            alignItems: "center",
            m: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              m: 1,
              gap: "0.5rem",
            }}
          >
            <TextField label="Name" variant="outlined" fullWidth />
            <TextField label="Email" variant="outlined" fullWidth />
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <TextField select defaultValue="+91" sx={{ width: "90px" }}>
                <MenuItem key={+91} value={+91}>
                  +91
                </MenuItem>
                <MenuItem key={+1} value={+1}>
                  +1
                </MenuItem>
                <MenuItem key={+61} value={+61}>
                  +61
                </MenuItem>
              </TextField>
              <TextField label="Number" variant="outlined" />
            </Box>
            <Button variant="contained" color="success">
              Contact now
            </Button>
          </Box>
        </Card>
      </Drawer>
      {/* 1 */}
      <Drawer
        open={openontactDrawer1}
        onClose={() => setOpenContactDrawer1(false)}
        anchor="bottom"
        PaperProps={{
          sx: { width: "100%", height: "60%" },
        }}
        // sx={{zIndex: 949}}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton>
            <PhoneOutlinedIcon fontSize="large" sx={{ color: "#76af70" }} />
          </IconButton>
          <DialogTitle sx={{ fontWeight: "bold", fontSize: "15px" }}>
            Contact our Real Estate Expert
          </DialogTitle>
          <IconButton
            onClick={() => {
              setOpenContactDrawer1(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Card
          sx={{
            maxWidth: { xs: "auto" },
            height: "361px",
            // borderRadius: "40px",
            border: "1px solid #7C7E80",
            boxShadow: "0px 0px 4px rgba(124, 126, 128, 1)",
            justifyContent: "center",
            alignItems: "center",
            m: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              m: 1,
              gap: "0.5rem",
            }}
          >
            <TextField label="Name" variant="outlined" fullWidth />
            <TextField label="Email" variant="outlined" fullWidth />
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <TextField select sx={{ width: "90px" }}>
                <MenuItem key={+91} value={+91}>
                  +91
                </MenuItem>
                <MenuItem key={+1} value={+1}>
                  +1
                </MenuItem>
                <MenuItem key={+61} value={+61}>
                  +61
                </MenuItem>
              </TextField>
              <TextField label="Number" variant="outlined" />
            </Box>
            <TextField select label="Select City" fullWidth>
              <MenuItem key={"Bangalore"} value={"Bangalore"}>
                Bangalore
              </MenuItem>
              <MenuItem key={"Chennai"} value={"Chennai"}>
                Chennai
              </MenuItem>
              <MenuItem key={"Goa"} value={"Goa"}>
                Goa
              </MenuItem>
            </TextField>
            <Button variant="contained" color="success">
              Contact Now
            </Button>
          </Box>
        </Card>
      </Drawer>
      {/* end */}
    </>
  );
};

export default Footer;
