import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../generic/Header";
import Footer from "../generic/Footer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Get My Housing - About Us</title>
        <meta property="og:title" content="Get My Housing - About Us" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        {/* <meta
          property="og:image"
          content={`https://getmyhousing.com/servicePhotos/water-purifier-service.jpg`}
        /> */}
      </Helmet>
      <Header />
      <Box
        sx={{
          width: "100%",
          backgroundColor: "red",
          textAlign: "center",
          py: 2,
          minHeight: "115px",
          marginTop: { md: "101px", xs: "72px" },
        }}
      >
        <Typography
          variant="h3"
          sx={{ color: "white", fontSize: { xs: "1.6rem", sm: "2rem" } }}
        >
          Fastest growing real estate portal
        </Typography>
      </Box>
      <Container>
        <Card sx={{ minWidth: 275, p: 2, mt: -3 }}>
          <CardContent>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: { sm: "2.125rem", xs: "1.5rem" },
                mb: 2,
              }}
            >
              About Get My Housing
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Get My Housing has firmly established itself as one of the leading
              and most successful name in Bangalore real estate market,
              {/* <Box component="span" sx={{ fontWeight: "bold" }}>
                  {" "}
                  MOHAMMED FAISAL KHAN
                </Box>{" "} */}
              Get My Housing is India’s online and 1st e-commerce real estate
              property portal, we deals with every aspect of the customers need
              in the real estate industry, it’s a online platform where buyers,
              seller and brokers/Agents can exchange information about real
              estate properties quickly, effectively and inexpensively, at
              getmyhousing.com, we can advertise a property,, search for a
              property, browse through properties, build your own property and
              keep yourself updated with the latest news and trends making the
              top headlines in the real estate sector and marketplace to buy,
              sell and rent residential, commercial,Industrial & properties.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              At Get My Housing, we understand people’s real estate needs, we
              help people to find the right property at the best price, be it a
              home to call their own. We help clients to manage any property
              solution or any paperwork related to property or page – Just get
              in touch with us soon.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Get My Housing is a brand name that is growing rapidly to meet the
              current real estate demands of Bangalore and other cities, over
              the years we have earned trust of our clientele through active
              interaction, speedy process and promptness in delivering, we offer
              real estate solution with a difference, our motto is to being
              building a long term relationship with our clientele, let us
              assist you in any of the real estate needs plus you do not have to
              go through endless procedures – we are computerize we want to make
              the journey as joyful as the moment when you find a perfect
              property, hence, we are with our partners and our customers from
              the start and be there when its matters the most, right from the
              online search to clients and brokers we work finding that perfect
              property at getmyhousing.com, we consult our clients to find joy!
            </Typography>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: { xs: "1.6rem", sm: "2rem" },
                lineHeight: { xs: "1.7", sm: "1.2" },
              }}
            >
              What is Get My Housing
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Get My Housing was initiated to provide an easy and convenient
              option for property buyers and seller and rental. The aim is to
              established a hassle free, easy to navigate online property portal
              for all things property, we provide fresh new approach to our
              esteemed user to search for properties to buy or rent and list
              their properties for selling and renting properties, at present,
              getmyhousing.com pride itself for having around multiple contacts
              spanning across Bangalore and + cities in India, of all, the
              website it will be paid listings at the end of FY 2019 Onwards, in
              addition to providing on online platform to real estate
              developers, brokers and property owners for listing their property
              for sale, purchase or rent, getmyhousing.com offer advertisement
              such has banners, home page link, and project pages to the clients
              for better visibility and branding in the market.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              With the ever –evolving online search behavior, getmyhousing.com
              shares information pertinent to real estate activities, assisting
              prospective buyers to make informed buying and renting decision,
              we make online property search easier and quicker and smarter.
            </Typography>
            <Grid container>
              <Grid item md={4} sx={{ boxShadow: "5px 5px #E5E1DA", p: 2 }}>
                <Typography>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    Our Vision:
                  </Box>
                  To be India's No.1 online platform that caters to all kinds of
                  contemporary real estate needs in the Indian real estate
                  market
                </Typography>
              </Grid>
              <Grid item md={4} sx={{ boxShadow: "5px 5px #E5E1DA", p: 2 }}>
                <Typography>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    Our Mission:
                  </Box>
                  To be the first choice for our consumers and partners in their
                  journey of discovering, renting, buying, selling and financing
                  any property
                </Typography>
              </Grid>
              <Grid item md={4} sx={{ boxShadow: "5px 5px #E5E1DA", p: 2 }}>
                <Typography>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    Our Values:
                  </Box>
                  Trust Integrity Transparency Flexibility
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default AboutUs;
