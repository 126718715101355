import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { config } from "../../config/config";
import EastIcon from "@mui/icons-material/East";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { apiList, invokeApi } from "../../apis/apiServices";
import { PriceFormatter, formatSegment } from "../../common/common";
import { Link, useNavigate } from "react-router-dom";
import LeadModal from "../end-user/LeadModal";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import required modules
import { Navigation, Pagination } from "swiper/modules";
const emptyPropertyImage = "/media/images/gridimgae.jpeg";

const LatestPropertiesSection = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const [noOfResult, setNoOfResult] = useState(4);

  const [leadModal, setLeadModal] = useState(false);
  const [leadSource, setLeadSource] = useState("");
  const [propertyId, setPropertyId] = useState(null);

  const [isPropertiesFetching, setIsPropertiesFetching] = useState(true);
  const [propertiesList, setPropertiesList] = useState([]);

  const handeChangeModal = () => setLeadModal((prevState) => !prevState);

  const displayBedrooms = (numOfBedrooms) => {
    // const { numOfBedrooms } = property || {};

    if (!numOfBedrooms) return null;

    if (numOfBedrooms === "Studio" || numOfBedrooms === "1 RK") {
      return numOfBedrooms;
    }

    return `${numOfBedrooms} BHK`;
  };

  // Usage in JSX
  {
    displayBedrooms();
  }

  useEffect(() => {
    const propertiesList = async () => {
      let params = {};
      const response = await invokeApi(
        config.apiDomains + apiList.AllActiveProperties,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response?.data.responseCode === "200") {
          setPropertiesList(response.data.propertyDtl);
        } else {
          alert("An error occurred while fetching latest data!");
        }
      } else {
        alert("An error occurred while fetching latest data!!");
      }
    };
    if (isPropertiesFetching && !propertiesList.length > 0) {
      propertiesList();
      setIsPropertiesFetching(false);
    }
  }, [cookies, isPropertiesFetching]);

  // useEffect(() => {
  //   setIsPropertiesFetching(true);
  // }, [cookies[config.preferencesCookie]?.city]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1300) {
        setNoOfResult(4);
      } else if (window.innerWidth > 900) {
        setNoOfResult(3);
      } else if (window.innerWidth > 600) {
        setNoOfResult(2);
      } else if (window.innerWidth > 0) {
        setNoOfResult(1);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* latest property list start  */}
      <Box component="section" sx={{ py: { md: 10, xs: 5 } }}>
        <Container maxWidth={"lg"}>
          <Box className="primary-heading-container">
            <Typography variant="h2" className="section-primary-heading">
              Latest Properties in {cookies[config.preferencesCookie]?.country}
            </Typography>
          </Box>
          <Swiper
            watchSlidesProgress={true}
            pagination={true}
            // navigation={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation, Pagination]}
            slidesPerView={noOfResult}
            className="mySwiper"
          >
            {propertiesList.length <= 0
              ? Array.from({ length: 15 }, (_, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      sx={{
                        margin: "0 5px",
                        minHeight: "290px",
                        zIndex: 1,
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={150}
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "left",
                            pl: { md: "12px", xs: "5px" },
                            mt: "10px",
                            mb: "4px",
                          }}
                        >
                          <Box sx={{ pt: 0.5 }}>
                            <Skeleton />
                            <Skeleton width="60%" />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              gap: "10px",
                            }}
                          >
                            <Skeleton width={"100%"} height={60} />
                            <Skeleton width={"100%"} height={60} />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))
              : propertiesList?.map((property, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      sx={{
                        margin: "0 5px", // Apply margin for spacing
                        minHeight: "360px",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          backgroundColor: "#e74c3c",
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          borderRadius: "5px",
                          color: "#fff",
                          fontWeight: "bold",
                          px: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {property.listingType}
                      </Typography>

                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "0px",
                        }}
                      >
                        <Box className="property-image-container">
                          <Box
                            component="img"
                            src={
                              property.imageData?.find(
                                (item) => item.imageType === "featured"
                              )?.imagePath || emptyPropertyImage
                            }
                          />
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <Box className="property-title-container">
                            <Link
                              to={`/property/${formatSegment(
                                property.listingType
                              )}/${formatSegment(
                                property.propertyName
                              )}/${formatSegment(
                                property.buildingType +
                                  "-" +
                                  property.propertyType +
                                  "-in-" +
                                  property.locality +
                                  "-" +
                                  property.city
                              )}/${property.propertyId}`}
                            >
                              {property?.title}
                              {/* {property?.furnishingType}{" "}
                                {displayBedrooms(property?.numOfBedrooms)}
                                {property?.propertyType} for{" "}
                                {property?.listingType} in{" "}
                                {property?.locality + "," || " "}
                                {" " + property?.city} */}
                            </Link>
                          </Box>
                          <Typography variant="caption">
                            {property.city}, {property.state}
                          </Typography>
                          <Box className="property-price-container">
                            <Typography className="property-price">
                              {PriceFormatter(property.rent)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            overflow: "auto",
                            mx: 1,
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            zIndex: 2,
                            "&::-webkit-scrollbar": {
                              display: "none",
                            },
                            "-ms-overflow-style": "none", // IE and Edge
                            "scrollbar-width": "none", // Firefox
                          }}
                        >
                          {property.numOfBedrooms && (
                            <Box
                              sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.8,
                                borderRadius: "8px",
                                mr: 1,
                              }}
                            >
                              {property.numOfBedrooms !== "Studio" ||
                              property.numOfBedrooms !== "1 RK"
                                ? property.numOfBedrooms + " BHK"
                                : property.numOfBedrooms}
                            </Box>
                          )}
                          {property.superBuiltupArea && (
                            <Box
                              sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.8,
                                borderRadius: "8px",
                                mr: 1,
                              }}
                            >
                              {property.superBuiltupArea +
                                " " +
                                property.areaUnit}
                            </Box>
                          )}
                          {property.furnishingType && (
                            <Box
                              sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.7,
                                borderRadius: "8px",
                                mr: 1,
                              }}
                            >
                              {property.furnishingType}
                            </Box>
                          )}
                          {property.positionStatus && (
                            <Box
                              sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.7,
                                borderRadius: "8px",
                                mr: 1,
                              }}
                            >
                              {property.positionStatus}
                            </Box>
                          )}
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          size="small"
                          endIcon={<QuestionAnswerIcon />}
                          sx={{
                            fontSize: { xs: "12px", md: "12px" },
                            textTransform: "capitalize",
                            backgroundColor: "#EAEAEA",
                            color: "#000",
                            "&:hover": {
                              color: "#000",
                              backgroundColor: "#EAEAEA",
                            },
                          }}
                          onClick={() => {
                            handeChangeModal();
                            setPropertyId(property.propertyId);
                            setLeadSource(property.propertyType);
                          }}
                          fullWidth
                        >
                          Enquiry Now
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          endIcon={<EastIcon />}
                          sx={{
                            fontSize: { xs: "12px", md: "12px" },
                            textTransform: "capitalize",
                            backgroundColor: "#DB282F",
                            color: "#fff",
                            "&:hover": {
                              color: "#fff",
                              backgroundColor: "#DB282F",
                            },
                          }}
                          onClick={() =>
                            navigate(
                              `/property/${formatSegment(
                                property.listingType
                              )}/${formatSegment(
                                property.propertyName
                              )}/${formatSegment(
                                property.buildingType +
                                  "-" +
                                  property.propertyType +
                                  "-in-" +
                                  property.locality +
                                  "-" +
                                  property.city
                              )}/${property.propertyId}`
                            )
                          }
                          fullWidth
                        >
                          More Details
                        </Button>
                      </CardActions>
                    </Card>
                  </SwiperSlide>
                ))}
            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
          </Swiper>
          <Box sx={{ py: { md: 2, xs: 1 }, textAlign: "center" }}>
            <Link
              to={`/property/${formatSegment(
                cookies[config.preferencesCookie]?.listingType
              )}`}
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                textDecoration: "none",
                color: "red",
                fontWeight: "700",
              }}
            >
              View Other Properties <ArrowRightAltIcon />
            </Link>
          </Box>
        </Container>
      </Box>
      {/* latest property list end  */}

      <LeadModal
        initialValue={leadModal}
        handleModal={handeChangeModal}
        leadSource={leadSource}
        propertyId={propertyId}
      />
    </>
  );
};

export default LatestPropertiesSection;
