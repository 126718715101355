import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import "./post-property-steps/Commercial.css";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import TenantDetails from "./post-property-steps/TenantDetails";
import ImageUploader from "./post-property-steps/UploadImage";
import PreView from "./post-property-steps/PreView";
import LocationDetails from "./post-property-steps/LocationDetails";
import AreaDetails from "./post-property-steps/area-details/AreaDetails";
import PropertyRent from "./post-property-steps/PropertyPrice";
import AdditionalDetails from "./post-property-steps/AdditionalDetails";
import FurnishingStatus from "./post-property-steps/FurnishingStatus";
import Amenities from "./post-property-steps/Amenities";
import LandMark from "./post-property-steps/LandMark";
import DefineYourProperty from "./post-property-steps/DefineYourProperty";
import PropertyDetails from "./post-property-steps/PropertyDetails";
import BrokerageDetails from "./post-property-steps/BrokerageDetails";
import { apiList, invokeApi } from "../../../../apis/apiServices";
import { config } from "../../../../config/config";
import { Bounce, toast } from "react-toastify";
import { saleUpdatedSteps } from "../../../../common/common";
import DefaultLoader from "../../../DefaultLoader";

function AddSaleProperty({
  nextStep,
  allStepsData,
  setAllStepsData,
  ...details
}) {
  const [cookies] = useCookies();

  const [stepValue, setstepValue] = useState("");

  const [formData, setFormData] = useState(allStepsData);

  const [activeStep, setActiveStep] = useState(2);

  // below the loading state is for loading when the data is transfering
  const [loading, setLoading] = useState(false);
  // below the state is stored the all steps and that value
  const [steps, setSteps] = useState(saleUpdatedSteps);
  // after solve the buy package api below state should give false and verifyPackage() this function should enable
  const [packageCheck, setpackageCheck] = useState(true);

  // below the useEffect is for set new data to the forData state vaiable
  useEffect(() => {
    setAllStepsData(formData);
  }, [activeStep]);

  // below the useEffect calling the verify userPackage
  // useEffect(() => {
  //   const verifyPackage = async () => {
  //     setLoading(true);
  //     let params = {};
  //     try {
  //       const response = await invokeApi(
  //         config.apiDomains + apiList.verifyUserPackage,
  //         params,
  //         cookies
  //       );
  //       if (response.status >= 200 && response.status < 300) {
  //         if (response.data.responseMessage === "Successful") {
  //           setpackageCheck(true);
  //           setLoading(false);
  //         } else {
  //           console.error("No packages are found for this user id");
  //           setLoading(false);
  //           setpackageCheck(false);
  //         }
  //       } else {
  //         setLoading(false);
  //         setpackageCheck(false);
  //       }
  //     } catch (error) {
  //       console.error("An error occurred while verifying packages:", error);
  //       setLoading(false);
  //     }
  //   };

  //   verifyPackage();
  // }, [cookies]);

  const saveProperty = async () => {
    setLoading(true);
    let params = {
      createdBy: cookies[config.cookieName]?.loginUserId,
      propertyName: formData.buildingName,
      propertyType: formData.propertiesType,
      listingType: formData.propertyListingType,
      buildingType: formData.buildingType,
      country: formData.selectedCountry,
      zone: formData.selectedTaluk,
      subLocality: formData.setsubLocality,
      pincode: formData.pinCode,
      state: formData.selectedState,
      landmark: formData.landMark,
      locality: formData.locality,
      propertyAddress: formData.propertyAdress,
      userPackageId: formData.userPackageId,
      latitude: formData.defaultMapLocation.latitude,
      longitude: formData.defaultMapLocation.longitude,
      propertyBrochure: formData.propertyBrochure,
      status: "yes",
      city:
        formData.selectedDistrict == "Bengaluru"
          ? "Bangalore"
          : formData.selectedDistrict,
      amenities: {
        amenity: formData.amenities,
      },
      youtubeLink: formData.videoLink,
      videoLink: formData.videoLink,

      brokerageType: formData.brokerage,
      brokergeValue: formData.brokeragedata,
      brokerageUnit: formData.brokerageUnit,

      // brokerageType: "",
      // brokergeValue: "",
      // brokerageUnit: "",

      pricingDetails: {
        rent: formData.rentAmount,
        rentType: formData.rentType,
        maintananceCost: formData.maintenanceAmout,
        maintananceCostType: formData.maintananceCostType,
        selectPriceInclude: formData.selectPriceInclude,
        securityDeposit: formData.securityDeposit,
        depositAmount: formData.customSecurityDeposit,
        bookingAmount: formData.bookingAmount,
        lockInPeriod: formData.lockInPeriod,
        lockInPeriodType: formData.lockInPeriodType,
        rentIncrement: formData.rentIncrement,
        perSqftPrice: formData.perSqftPrice,
      },

      additionalDetails: {
        serviceLiftAvailability: formData.serviceLiftAvailability,
        serviceLiftAvailabilityCount: formData.noOfServiceLifts,
        waterSource: formData.waterSource,
        currentlyLeasedOut: formData.currentlyLeasedOut,
        modifyInterior: formData.modifyInterior,
        brandNewInterior: formData.newInterior,
        interestedInCoWorking: formData.interestInCoWorking,
        buildingGrade: formData.buildingGrade,
        cafeteria: formData.pantryCafeteria,
        taxGovtCharges: formData.taxCharges,
        electricityCharges: formData.eletricityCharges,
        powerInKv: formData.electricPower,
        liftAvailable: formData.liftAvailability,
        liftCount: formData.noOfLifts,
        flooringType: formData.flooringType,
        flooringLiving: formData.livingFlooringType,
        flooringBathroom: formData.bathRoomFlooringType,
        flooringKitchen: formData.kitchenFlooringType,
        flooringBedroom: formData.bedRoomFlooringType,
        flooringMasterBedroom: formData.MasterBedRoomFlooringType,
        flooringBalcony: formData.balconyFlooringType,
        flooringOther: formData.otherFlooringType,
        parking2Wheeler: formData.twoWheeler,
        parking2OpenCount: formData.twoWheelerOpenType,
        parking2CoverCount: formData.twoWheelerClosedType,
        parking4Wheeler: formData.fourWheeler,
        parking4OpenCount: formData.fourWheelerOpenType,
        parking4CoverCount: formData.fourWheelerClosedType,
        powerBackup: formData.powerBackUp,
        waterResource: formData.waterSource,
        overLookingView: formData.overLookingView,
        frontRoadWidth: formData.roadWidth,
        frontRoadWidthType: formData.roadWidthUnit,
      },

      defineProperty: {
        defineLocation: formData.defineLocation
          ? formData.defineLocation.join(", ")
          : "",
        explainingPrice: formData.explainingPrice
          ? formData.explainingPrice.join(", ")
          : "",
        explainingProperty: formData.explainingTheProperty
          ? formData.explainingTheProperty.join(", ")
          : "",
        defineSizeAndStructure: formData.defineSizeStructure
          ? formData.defineSizeStructure.join(", ")
          : "",
        description: formData.description,
      },

      landMarks: {
        hospitalDistance: formData.hospitalDistance,
        hospitalDistanceType: formData.hospitalDistanceUnit,
        airportDistance: formData.airportDistance,
        airportDistanceType: formData.airportDistanceUnit,
        railwayStationDistance: formData.railwayDistance,
        railwayStationDistanceType: formData.railwayDistanceUnit,
        atmDistance: formData.atmDistance,
        atmDistanceType: formData.atmDistanceUnit,
        schoolDistance: formData.schoolDistance,
        schoolDistanceType: formData.schoolDistanceUnit,
        shoppingMallDistance: formData.shoppingMallDistance,
        shoppingMallDistanceType: formData.shoppingMallDistanceUnit,
        bankDistance: formData.bankDistance,
        bankDistanceType: formData.bankDistanceUnit,
        busStopDistance: formData.busStopDistance,
        busStopDistanceType: formData.busStopDistanceUnit,
        metroStationDistance: formData.metroDistance,
        metroStationDistanceType: formData.metroDistanceUnit,
      },

      tenantStatus: {
        foodPreference: formData.foodPreference,
        religiousType: formData.religiousType,
        petsAllowed: formData.petsAllowed,
        tenantType: formData.transactionType
          ? formData.transactionType.join(", ")
          : "",
        workPreference: formData.workPreference,
        bachelorsAllowed: formData.tenentBachelorsAllowed
          ? formData.tenentBachelorsAllowed.join(", ")
          : "",
        sprinstersAllowed: formData.tenentSpinstersAllowed
          ? formData.tenentSpinstersAllowed.join(", ")
          : "",
      },

      propertyStatus: {
        tenantPreLeasedUnit: formData.tenantPreLeasedUnit,
        govtApproved: formData.govtApproved,
        ageOfProperty: formData.ageofProperty,
        availableFor: formData.availableFor,
        availableFrom: formData.availbleFrom,
        availableFromDate: formData.availableFromDate,
        entranceWidth: formData.widthOfPropertyStatus,
        createdDate: formData.positionStatusDate,
        positionStatus: formData.positionStatus,
        occupancyDays: formData.occupancyDays,
        ownershipType: formData.owenershipType,
        aboutPropertySuitableFor: formData.propertySuitableFor
          ? formData.propertySuitableFor.join(", ")
          : "",
        locationHub: formData.locationHub
          ? formData.locationHub.join(", ")
          : "",
        heightSealing: formData.heightSealingOfPropertyStatus,
        locatedNear: formData.locatedNear
          ? formData.locatedNear.join(", ")
          : "",
        positionStatusType: formData.positionStatusType,
      },

      furnishingStatus: {
        furnishingType: formData.furnishingStatus,
        airConditionAvailable: formData.ac.name,
        airConditionCount: formData.ac.count,
        bedsAvailable: formData.beds.name,
        bedsCount: formData.beds.count,
        gasConnectionAvailable: formData.gas.name,
        gasConnectionCount: formData.gas.count,
        modularKitchen: formData.modularKitchen.name,
        modularKitchenCount: formData.modularKitchen.count,
        ledLightsAvailable: formData.led.name,
        ledLightsCount: formData.led.count,
        washingMachineAvailable: formData.washingMachine.name,
        washingMachineCount: formData.washingMachine.count,
        refrigeratorAvailable: formData.refrigerator.name,
        refrigeratorCount: formData.refrigerator.count,
        sofaAvailable: formData.sofa.name,
        sofaCount: formData.sofa.count,
        tvAvailable: formData.tv.name,
        tvCount: formData.tv.count,
        wardrobeAvailable: formData.wardrobe.name,
        wardrobeCount: formData.wardrobe.count,
        waterPurifier: formData.waterPurifier.name,
        waterPurifierCount: formData.waterPurifier.count,
        fan: formData.fan.name,
        fanCount: formData.fan.count,
        exhaustFan: formData.exhaustFan.name,
        exhaustFanCount: formData.exhaustFan.count,
        stove: formData.stove.name,
        stoveCount: formData.stove.count,
        curtains: formData.curtains.name,
        curtainsCount: formData.curtains.count,
        chimney: formData.chimney.name,
        chimneyCount: formData.chimney.count,
        microWave: formData.microWave.name,
        microWaveCount: formData.microWave.count,
        diningTables: formData.diningTables.name,
        diningTablesCount: formData.diningTables.count,
        wifiAvailable: formData.wifi?.name,
        wifiCount: formData.wifi?.count,
        geyserAvailable: formData.geyser?.name,
        geyserCount: formData.geyser?.count,
        chairs: formData.chairs.name,
        chairsCount: formData.chairs.count,
        linear: formData.linear?.name,
        linearCount: formData.linear?.count,
        cubical: formData.cubical?.name,
        cubicalCount: formData.cubical?.count,
        officeTables: formData.officeTables.name,
        officeTablesCount: formData.officeTables.count,
        meetingRooms: formData.meetingRoom?.name,
        meetingRoomsCount: formData.meetingRoom?.count,
        medicalKits: formData.mediclKits.name,
        medicalKitsCount: formData.mediclKits.count,
        conferenceRooms: formData.confernceRooms.name,
        conferenceRoomsCount: formData.confernceRooms.count,
        recreationalFacility: formData.recreational.count,
        recreationalFacilityCount: formData.recreational.count,
        printingMachines: formData.printingMachine.name,
        printingMachinesCount: formData.printingMachine.count,
        smartBoard: formData.smartBoard.name,
        smartBoardCount: formData.smartBoard.count,
        projectors: formData.projectors.name,
        projectorsCount: formData.projectors.count,
        tables: formData.tables?.name,
        tablesCount: formData.tables?.count,
        coffeeMachines: formData.coffieMachine.name,
        coffeeMachinesCount: formData.coffieMachine.count,
      },

      propertyAreaDetails: {
        keepItPrivate: formData.keepItPrivate,
        builtupPlotArea: formData.builtupArea,
        plotArea: formData.plotArea,
        superBuiltupArea: formData.superBuiltupArea,
        carpetArea: formData.carpetArea,
        salableArea: formData.salableArea,
        areaUnit: formData.areaUnit,
        privatePoolAvailability: formData.privatePoolAvailability,
        privateGardenAvailability: formData.privateGardenAvailability,
        privateGardenArea: formData.privateGardenArea,
        noOfBedrooms: formData.numberOfBedRooms,
        noOfBathrooms: formData.numberOfBathRooms,
        noOfBalconies: formData.numberOfBalconies,
        additionalRooms: formData.additionalRooms,
        floorNo: formData.floorNumber,
        towerBlockNo: formData.towerOrBlockNumber,
        flatNo: formData.flatNumber,
        propertyLevel: formData.propertyLevel,
        cornerFlat: formData.cornerUnit ? formData.cornerUnit : null,
        terraceAreaFlag: formData.terraceAreaFlag,
        terraceArea: formData.terraceArea,
        terraceAreaUnit: formData.terraceAreaFlag,
        totalFloors: formData.totalFloor,
        openSides: formData.openSides,
        facing: formData.facing,
        occupancyType: formData.occupancyType,
        occupancyCertificate: formData.occupancyCertificate,
        privateWashroom: formData.privateWashRoom,
        privateWashroomCount: formData.privateWashroomCount
          ? formData.privateWashroomCount.label
          : "",
        publicWashroom: formData.publicWashRoom,
        publicWashroomCount: formData.publicWashroomCount,
        gardenArea: formData.privateGardenArea,
        pantry: formData.pantryCafeteria
          ? formData.pantryCafeteria
          : formData.pantryOrCafeteria,
        spaceType: formData.spaceType,
        buildingStatus: formData.occupancyType,
        compoundWallMade: formData.compoundWallMade,
        breadthFeet: formData.breadthInFeet,
        lengthFeet: formData.lengthInFeet,
        noOfFloorsAllowed: formData.numberOfFloorsAllowed,
      },
      reraStatus: {
        reraAvailable: formData.reraAvailable,
        reraNo: formData.reraNumber,
      },
      propertyImages: formData.allImages,
      propertyFloorRooms: formData.data,
    };

    if (formData.propertiesType === "PG/Co-living") {
      params.pgRegulations = {
        nonVegAllowed: formData.nonVeg,
        oppositeSex: formData.oppSex,
        anyTimeAllowed: formData.time,
        visitorAllowed: formData.visitor,
        guardianAllowed: formData.guardian,
        drinkingAllowed: formData.drinks,
        smokingAllowed: formData.smoking,
      };
    }

    if (formData.propertiesType === "PG/Co-living") {
      params.pgOwnerDetails = {
        propertyManagedBy: formData.propertyManagedBy
          ? formData.propertyManagedBy.join(", ")
          : "",
        propertyManagerStay: formData.propertyManagedStaysAtProperty,
      };
    }
    if (formData.propertiesType === "PG/Co-living") {
      params.pgDetails = {
        totalBeds: formData.noOfPgBeds,
        pgFor: formData.pgFor,
        mealsAvailable: formData.mealsAvailable,
        commonAreas: formData.commonAreas
          ? formData.commonAreas.join(", ")
          : "",
        noticePeriod: formData.noticePeriod,
        lockInPeriod: formData.pgLockInPeriod,
        bestSuitedFor: formData.bestSuitedFor,
      };
    }

    if (formData.propertiesType === "PG/Co-living") {
      params.pgRoomDetails = {
        totalBedsInRoom: formData.noOfBedsinRoom
          ? formData.noOfBedsinRoom.join(", ")
          : "",
        roomType: formData.roomType ? formData.roomType.join(", ") : "",
        rent: formData.pgRentAmount,
        rentType: formData.pgRentType,
        securedDeposit: formData.pgSecurityDeposit,
        securedDepositAmount: formData.pgCustomSecurityDeposit,
        parking2Wheeler: formData.pgTwoWheeler,
        parking2OpenType: formData.pgTwoWheelerOpenType?.label || "",
        parking2CoverType: formData.pgTwoWheelerClosedType?.label || "",
        parking4Wheeler: formData.pgFourWheeler,
        parking4OpenType: formData.pgFourWheelerOpenType?.label || "",
        parking4CoverType: formData.pgFourWheelerClosedType?.label || "",
        facilityOffered: formData.facilityOffered
          ? formData.facilityOffered.join(", ")
          : "",
      };
    }

    // Save Property
    let response = await invokeApi(
      config.apiDomains + apiList.saveProperty,
      params,
      cookies
    );

    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Property data saved successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      toast.error(response.data.responseMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      alert(
        "Something went wrong while saving property data. Please try again later!!"
      );
    }
    setLoading(false);
    setActiveStep(0);
  };

  useEffect(() => {
    if (activeStep > 0 && activeStep < steps.length) {
      setstepValue(steps[activeStep].label);
    } else if (activeStep === 0) {
      nextStep(null);
    } else {
      setstepValue(null);
    }
  }, [activeStep, steps]);

  // Skipping Furnishing Steps

  const handleNextToSkipSubPropertyType = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackToSkipSubPropertyType = () => {
    if (formData.propertiesType !== "Preleased Properties") {
      setActiveStep(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNextToSkipTenantDetails = () => {
    if (formData.propertiesType !== "Preleased Properties") {
      setActiveStep(6);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBackToSkipTenantDetails = () => {
    if (formData.propertiesType !== "Preleased Properties") {
      setActiveStep(4);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNextToSkipFurnishingStatus = () => {
    if (
      formData.propertiesType !== "Residential Plot/Land" &&
      formData.propertiesType !== "Industrial Plot/Land" &&
      formData.propertiesType !== "Commercial Plot/Land" &&
      formData.propertiesType !== "Estate/Plantation" &&
      formData.propertiesType !== "Farm Plot/Land"
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep(10);
    }
  };

  const handleBackToSkipFurnishingStatus = () => {
    if (
      formData.propertiesType !== "Residential Plot/Land" &&
      formData.propertiesType !== "Industrial Plot/Land" &&
      formData.propertiesType !== "Commercial Plot/Land" &&
      formData.propertiesType !== "Estate/Plantation" &&
      formData.propertiesType !== "Farm Plot/Land"
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else if (
      formData.buildingType === "Industrial" ||
      formData.propertiesType === "Shopping Mall" ||
      formData.propertiesType === "Hotel/Resorts" ||
      formData.propertiesType === "Estate/Plantation" ||
      formData.propertiesType === "Farm Plot/Land" ||
      formData.propertiesType === "Residential Plot/Land" ||
      formData.propertiesType === "Guest House/Banquet Hall"
    ) {
      setActiveStep(7);
    } else {
      setActiveStep(8);
    }
  };

  const handleNextToSkipReraStatus = () => {
    if (
      formData.buildingType !== "Industrial" &&
      formData.propertiesType !== "Shopping Mall" &&
      formData.propertiesType !== "Hotel/Resorts" &&
      formData.propertiesType !== "Preleased Properties" &&
      formData.propertiesType !== "Estate/Plantation" &&
      formData.propertiesType !== "Office Space" &&
      formData.propertiesType !== "Commercial Building" &&
      formData.propertiesType !== "Retail Shop/Showroom" &&
      formData.propertiesType !== "Office Space in IT Park/SEZ" &&
      formData.propertiesType !== "Commercial Plot/Land" &&
      formData.propertiesType !== "Farm Plot/Land" &&
      formData.propertiesType !== "Residential Plot/Land" &&
      formData.propertiesType !== "Guest House/Banquet Hall"
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (
      formData.propertiesType === "Residential Plot/Land" ||
      formData.propertiesType === "Industrial Plot/Land" ||
      formData.propertiesType === "Commercial Plot/Land" ||
      formData.propertiesType === "Estate/Plantation" ||
      formData.propertiesType === "Farm Plot/Land"
    ) {
      setActiveStep(10);
    } else {
      setActiveStep(9);
    }
  };

  const handleBackToSkipReraStatus = () => {
    if (
      formData.buildingType !== "Industrial" &&
      // formData.buildingType !== "Commercial" &&
      formData.propertiesType !== "Shopping Mall" &&
      formData.propertiesType !== "Hotel/Resorts" &&
      formData.propertiesType !== "Preleased Properties" &&
      formData.propertiesType !== "Estate/Plantation" &&
      formData.propertiesType !== "Office Space" &&
      formData.propertiesType !== "Commercial Building" &&
      formData.propertiesType !== "Retail Shop/Showroom" &&
      formData.propertiesType !== "Office Space in IT Park/SEZ" &&
      formData.propertiesType !== "Commercial Plot/Land" &&
      formData.propertiesType !== "Farm Plot/Land" &&
      formData.propertiesType !== "Residential Plot/Land" &&
      formData.propertiesType !== "Guest House/Banquet Hall"
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveStep(7);
    }
  };

  useEffect(() => {
    const updatedStepsCopy = saleUpdatedSteps.map((step) => ({ ...step }));

    if (
      [
        "Residential Plot/Land",
        "Farm Plot/Land",
        "Industrial Plot/Land",
        "Commercial Plot/Land",
        "Estate/Plantation",
      ].includes(formData.propertiesType)
    ) {
      const indexOfFurnishingStatus = updatedStepsCopy.findIndex(
        (step) => step.label === "Furnishing Status"
      );
      if (indexOfFurnishingStatus !== -1) {
        updatedStepsCopy[indexOfFurnishingStatus].isvisible = false;
      }
    }
    if (formData.propertiesType !== "Preleased Properties") {
      const indexOfFurnishingStatus = updatedStepsCopy.findIndex(
        (step) => step.label === "ROI/Tenant Details"
      );
      if (indexOfFurnishingStatus !== -1) {
        updatedStepsCopy[indexOfFurnishingStatus].isvisible = false;
      }
    }
    if (formData.propertiesType !== "Preleased Properties") {
      const indexOfFurnishingStatus = updatedStepsCopy.findIndex(
        (step) => step.label === "Sub-property Type"
      );
      if (indexOfFurnishingStatus !== -1) {
        updatedStepsCopy[indexOfFurnishingStatus].isvisible = false;
      }
    }
    if (
      formData.buildingType === "Industrial" ||
      formData.propertiesType === "Shopping Mall" ||
      formData.propertiesType === "Hotel/Resorts" ||
      formData.propertiesType === "Preleased Properties" ||
      formData.propertiesType === "Estate/Plantation" ||
      formData.propertiesType === "Office Space" ||
      formData.propertiesType === "Commercial Building" ||
      formData.propertiesType === "Retail Shop/Showroom" ||
      formData.propertiesType === "Office Space in IT Park/SEZ" ||
      formData.propertiesType === "Commercial Plot/Land" ||
      formData.propertiesType === "Farm Plot/Land" ||
      formData.propertiesType === "Residential Plot/Land" ||
      formData.propertiesType === "Guest House/Banquet Hall"
    ) {
      const indexOfFurnishingStatus = updatedStepsCopy.findIndex(
        (step) => step.label === "RERA Status"
      );
      if (indexOfFurnishingStatus !== -1) {
        updatedStepsCopy[indexOfFurnishingStatus].isvisible = false;
      }
    }

    setSteps(updatedStepsCopy);
  }, [formData.propertiesType, formData.buildingType]);

  return (
    <>
      {packageCheck && (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              margin: "auto",
              overflowX: "auto",
              gap: "10px",
              pl: 2,
              width: "100%",
            }}
            className="custom-scrollbar"
          >
            {activeStep !== 0 &&
              activeStep !== 1 &&
              activeStep !== steps.length &&
              steps.map((item) => {
                if (item.isvisible) {
                  return (
                    <Box
                      key={item.label}
                      className="mydict"
                      sx={{ boxSizing: "initial", userSelect: "none" }}
                    >
                      <label
                        className={`mydict1 border1 ${
                          activeStep === item.value ? "active-step" : ""
                        }`}
                        // onClick={() => {
                        //   setActiveStep(item.value);
                        // }}
                        style={{
                          marginRight: "5px",
                          marginBottom: "5px",
                          boxShadow: "none",
                        }}
                      >
                        <input
                          type="radio"
                          name="20maage2"
                          value={item.label}
                          checked={stepValue === item.label}
                          readOnly
                        />
                        <span
                          className="border1"
                          style={{
                            marginRight: "15px",
                            boxShadow: "none",
                            fontFamily: "Mulish, sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.label}
                        </span>
                      </label>
                    </Box>
                  );
                }
                return null;
              })}
          </Box>

          {activeStep === steps.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "40vh",
              }}
            >
              {loading && (
                <>
                  <Typography
                    sx={{
                      mt: 10,
                      fontSize: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Please wait... Property details are saving
                  </Typography>
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    <CircularProgress sx={{ color: "black" }} />
                  </Stack>
                </>
              )}
              {!loading && (
                <>
                  <PreView
                    next={() =>
                      setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    }
                    back={() =>
                      setActiveStep((prevActiveStep) => prevActiveStep - 1)
                    }
                    updateFormData={(updatedData) =>
                      setFormData({
                        ...formData,
                        ...updatedData,
                      })
                    }
                    formData={formData}
                    detailsId={details?.id}
                  />
                  <Button
                    onClick={saveProperty}
                    variant="outlined"
                    sx={{
                      color: "black",
                      width: "8%",
                      mt: 3,
                      mb: 2,
                      borderColor: "black",
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="inherit"
                    onClick={() =>
                      setActiveStep((prevActiveStep) => prevActiveStep - 1)
                    }
                  >
                    Back
                  </Button>
                </>
              )}
            </Box>
          ) : (
            <Box sx={{ ml: { md: 3, xs: 0 }, mt: 2 }}>
              {/* {activeStep === 1 && (
                <SubPropertyType
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )} */}
              {activeStep === 2 && (
                <LocationDetails
                  next={handleNextToSkipSubPropertyType}
                  back={() => {
                    if (formData.propertiesType === "Preleased Properties") {
                      setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    } else nextStep(null);
                  }}
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 3 && (
                <AreaDetails
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={handleBackToSkipSubPropertyType}
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 4 && (
                <PropertyDetails
                  next={handleNextToSkipTenantDetails}
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 5 && (
                <TenantDetails
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 6 && (
                <PropertyRent
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={handleBackToSkipTenantDetails}
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 7 && (
                <AdditionalDetails
                  next={handleNextToSkipReraStatus}
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 8 && (
                <FurnishingStatus
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={handleBackToSkipReraStatus}
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                  activeSteps={activeStep}
                />
              )}
              {activeStep === 9 && (
                <Amenities
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={handleBackToSkipFurnishingStatus}
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 10 && (
                <LandMark
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 11 && (
                <DefineYourProperty
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 12 && (
                <ImageUploader
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === 13 && (
                <BrokerageDetails
                  next={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                  back={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  updateFormData={(updatedData) =>
                    setFormData({ ...formData, ...updatedData })
                  }
                  formData={formData}
                />
              )}
              {activeStep === steps.length && (
                <Stack
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress sx={{ color: "black" }} />
                </Stack>
              )}
            </Box>
          )}
        </>
      )}
      {activeStep === 0 && loading && <DefaultLoader />}
      {activeStep === 0 && !loading && !packageCheck && (
        <Typography>Please purchase a package</Typography>
      )}
    </>
  );
}

export default AddSaleProperty;
